/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2ce902");

@font-face {
  font-family: "MuseoSansRounded-300";
  src: url("webfonts/2CE902_0_0.eot");
  src: url("webfonts/2CE902_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/2CE902_0_0.woff2") format("woff2"),
    url("webfonts/2CE902_0_0.woff") format("woff"),
    url("webfonts/2CE902_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "NexaSlabHeavy";
  src: url("webfonts/2CE902_5_0.eot");
  src: url("webfonts/2CE902_5_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/2CE902_5_0.woff2") format("woff2"),
    url("webfonts/2CE902_5_0.woff") format("woff"),
    url("webfonts/2CE902_5_0.ttf") format("truetype");
}
