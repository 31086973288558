@import "../../../scss/variables";

.ParticipantSummary {
  width: 100%;

  .Pink {
    color: $pink;
  }

  .ParticipantNumber {
    margin: 0 !important;
    font: $font-size-p-b3	$font-secondary;
    font-weight: bold;
  }

  .Content {
    font: $font-size-p-b4 $font-secondary;
  }
}
