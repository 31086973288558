@import "fonts";
@import "normalize";
@import "variables";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$theme-colors: (
  "dark": $black,
  "light": $white,
  "primary": $blue,
  "secondary": $pink,
  "success": $green,
  "warning": $orange,
);

@import "node_modules/bootstrap/scss/bootstrap";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

h1 {
  font: $font-size-h1 $font-primary !important;
  line-height: $line-height-h1;
  color: $default-font-color;

  @media (max-width: $sm-bp) {
    font-size: $font-size-h3 !important;
  }
}

h2 {
  font: $font-size-h2 $font-primary;
  line-height: $line-height-h2;
  color: $default-font-color;

  @media (max-width: $sm-bp) {
    font-size: $font-size-h4;
  }
}

h3 {
  font: $font-size-h3 $font-primary;
  line-height: $line-height-h3;
  color: $default-font-color;

  @media (max-width: $sm-bp) {
    font-size: $font-size-h5;
  }
}

h4 {
  font: $font-size-h4 $font-primary;
  line-height: $line-height-h4;
  color: $default-font-color;

  @media (max-width: $sm-bp) {
    font-size: $font-size-h5;
  }
}

h5 {
  font: $font-size-h5 $font-primary;
  line-height: $line-height-h5;
  color: $default-font-color;
}

h6 {
  font: $font-size-h6 $font-primary;
  line-height: $line-height-h6;
  color: $default-font-color;
}

.s1,
p {
  font: $font-size-p-s1 $font-secondary;
  line-height: $line-height-p-s1;
  color: black;
}

.b1 {
  font: $font-size-p-b1 $font-secondary;
  line-height: $line-height-p-b1;
  color: black;
}

.b2 {
  font: $font-size-p-b2 $font-secondary;
  line-height: $line-height-p-b2;
  color: black;
}

.b3 {
  font: $font-size-p-b3 $font-secondary;
  line-height: $line-height-p-b3;
  color: black;
}

.b4 {
  font: $font-size-p-b4 $font-secondary;
  line-height: $line-height-p-b4;
  color: black;
}

@media (min-width: $md-bp) {
  .container {
    max-width: 900px !important;
  }
}
.row {
  --bs-gutter-x: 1.875rem;
}
.Document_ButtonSave__1boZm {
  height: 0px !important;
}
.text-right {
  text-align: right !important;
}

.modal-header .btn-close {
  margin: 0.5rem;
  position: absolute;
  right: 0;
}

.link-style {
  color: $blue !important;
  text-decoration: underline !important;
}

.withdrawal-wrapper {
  display: table-row;
}

.withdrawal-span {
  display: table-cell;
  width: 50%;
}

.withdrawal-span-text-align {
  text-align: end;
  text-decoration: underline;
}

.modal-backdrop ~ .modal-backdrop,
.modal ~ .modal {
  display: none !important;
}
