@import "../../../scss/variables";

.OpenAccount {
  padding: 25px 0 25px 0;
  display: flex;
  align-items: center;

  .OpenAccountIcon {
    flex: 0 0 65px;
    margin-right: 25px;
  }

  .OpenAccountContent {
    .OpenAccountHeader {
      margin: 0;
    }

    .Content {
      font: $font-size-p-b4 $font-secondary;
      margin: 0;
    }
  }
}
