@import "../scss/variables";

.AnimatedButton-17aee5,
.AnimatedButton-e73180,
.AnimatedButtonDisabled-17aee5,
.AnimatedButtonDisabled-e73180 {
  cursor: pointer;
  font: $font-size-h5 $font-primary;
  color: $white;
  border: $default-border-width solid $black;
  border-radius: $default-border-radius;
  text-align: center;
  padding: $default-padding;
  width: 100%;
}

.AnimatedButton-17aee5 {
  background-color: $blue;
}

.AnimatedButton-e73180 {
  background-color: $pink;
}

.AnimatedButtonDisabled-17aee5 {
  background-color: $blue-soft;
  cursor: not-allowed;
}

.AnimatedButtonDisabled-e73180 {
  background-color: $pink-soft;
  cursor: not-allowed;
}

.AnimatedButton-e73180[anim="1"],
.AnimatedButton-17aee5[anim="1"] {
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.AnimatedButton-17aee5[anim="1"] {
  animation-name: buttonAnim-17aee5;
}

.AnimatedButton-e73180[anim="1"] {
  animation-name: buttonAnim-e73180;
}

@keyframes buttonAnim-e73180 {
  0% {
    background-color: $blue;
  }
  100% {
    background-color: $pink;
  }
}

@keyframes buttonAnim-17aee5 {
  0% {
    background-color: $pink;
  }
  100% {
    background-color: $blue;
  }
}
