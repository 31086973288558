@import "../../../scss/variables";

.ResizableIcon {
  svg {
    width: inherit;
    height: inherit;
  }
}

.ResizableIconBordered-000000 {
  svg {
    width: inherit;
    height: inherit;
    border: solid 1px $black;
    border-radius: 50%;
  }
}

.ResizableIconBordered-17aee5 {
  svg {
    width: inherit;
    height: inherit;
    border: solid 1px $blue;
    border-radius: 50%;
  }
}

.ResizableIconBordered-e73180 {
  svg {
    width: inherit;
    height: inherit;
    border: solid 1px $pink;
    border-radius: 50%;
  }
}
