@import "./scss//variables";

* {
  margin: 0;
}

html,
body,
#root,
#root > div {
  height: 100% !important;
}

.AppWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Background {
  z-index: -50;
  position: fixed;
  min-height: 100%;
  min-width: 100%;
  background-image: url("./images/vertical-rule.svg");
  background-position: 50%;
  background-repeat: repeat-y;
}

.Main {
  flex-grow: 1;
}

.Main,
.Footer {
  flex-shrink: 0;
}

.Footer {
  height: $footer-height;
}

.Document_ButtonSave__1boZm {
  height: 0px !important;
}

.BackButtonWrapper {
  position: absolute;
  left:-90px;
  top:10px;
}
.BackButton {
  width:75px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
