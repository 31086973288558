@import "../../scss/variables";

.Container {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  color: $white;

  .Label {
    font: $font-size-h5 $font-primary;
    padding: 0.2rem 0;
  }
}
