@import "../../scss/variables";

.Wrapper {
  font: $font-size-p-b1 $font-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: $sm-bp) {
    font-size: $font-size-p-b3;
  }
}

.English {
  padding-left: 10px;
  cursor: pointer;
  font-weight: normal;
}

.Dutch {
  padding: 10px;
  cursor: pointer;
  font-weight: normal;
}

.Active {
  color: $blue;
}
