@import "../../scss/variables";

.Container {
  padding: 28px 1rem 28px;
  max-width: 36rem;

  .Tooltip {
    border-radius: 0.375rem;
    color: $white;
    padding: 0.5rem 0.75rem;
    background-color: rgb(35, 38, 39);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    z-index: 30;
  }
}
