@import "../../../scss/variables";

.Divider {
  width: 100%;
  height: 3px;
  border-bottom: solid 3px $black;
}

.PinkDivider {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px $pink;
}
