@import "../../scss/variables";

.IconModal svg {
  margin: 0 auto;
  display: block;
  padding: 3.75rem;
}

.IconModalContent {
  padding: 3.75rem;
  text-align: center;
}

div.IconModalClose {
  padding: 2rem;

  @media only screen and (max-width: $md-bp) {
    padding: 3rem;
  }
}

button.IconModalContinue {
  float: right;
  width: 15rem;
  border-radius: 4px;

  @media only screen and (max-width: $l-bp) {
    width: 100%;
  }
}
