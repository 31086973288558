@import "../../scss/variables";

.Header {
  text-align: center;
  color: $blue;
}

.Download {
  cursor: pointer;
}

.LoadMore {
  color: $blue;
  font: $font-size-p-b3 $font-secondary;
  text-decoration: underline;
  cursor: pointer;
}

.LoadMoreDisabled {
  display: none;
}

.ConfirmationButton {
  background-color: $blue;
}

@media only screen and (max-width: 768px) {
  button {
    margin-bottom: 3rem !important;
  }
}

.download {
  background: $blue !important;
  color: white !important;
  width: 9rem !important;
  height: 2rem !important;
  margin-left: .5rem !important;
  margin-top: 2.5rem !important;
  color: white;
  margin-bottom: .5rem;
  border: 1px solid black;
  box-sizing: content-box;
  position: absolute;
  z-index: 50;

  a {
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: white;
    text-decoration: none;
  }
}
.row {
  border-bottom: 1px solid $blue;
  span {
    float: right;
  }
}

.pdfViewer {
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
}