@import "../../scss/variables";

.Underline {
  text-decoration: underline !important;
}

.Blue {
  color: $blue;
}

.Pink {
  color: $pink;
}

.White {
  color: $white;
}

.Black {
  color: $black;
}
