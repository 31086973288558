@import "../../../scss/variables";

.SectionHeader {
  padding-top: 25px;
  padding-bottom: 0;

  .Content {
    font: $font-size-p-b4 $font-secondary;
    margin: 0;
  }

  .RemoveMargins {
    margin: 0 !important;
  }
}


