@import "../../scss/variables";

.Jumbotron {
  display: flex;
  align-items: center;

  .container,
  .row,
  .col {
    padding: 0 none !important;
  }

  padding-bottom: 24px !important;

  .Header {
    width: 100% !important;
    text-align: center !important;
  }

  @media only screen and (max-width: $sm-bp) {
    padding-top: 2rem !important;
  }

  .TextRight {
    text-align: end !important;

    @media only screen and (max-width: $sm-bp) {
      text-align: start !important;
      color: $white;
    }
  }

  .Icon {
    position: relative;
    top: 37.5px;
    left: -42.5px;

    @media (max-width: $md-bp) {
      display: none;
    }
  }

  @media only screen and (min-width: $sm-bp) {
    min-height: 255px;
    margin-bottom: 0 !important;
  }

  .Content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      width: auto !important;
      padding: 0;
  }

    @media only screen and (max-width: $sm-bp) {
      padding-left: 25px;
      flex-direction: column;
      font: $font-size-p-b1 $font-secondary !important;
      margin-bottom: 2rem !important;
    }
  }
}

.Background-17aee5 {
  background: $blue !important;
}

.Background-f0e928 {
  background: $yellow !important;
}

.Background-e73180 {
  background: $pink !important;
}

.Background-bcbcbc {
  background: $grey-medium !important;
}

.Background-f5d6e4 {
  background: $pink-medium !important;
  border-bottom: solid $default-border-width $pink;
}

.PseudoRelative {
  position: absolute;
  width: 0;
  height: 0;
}

.BackButton {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  top: 150px;
  left: -100px;
  cursor: pointer;
}
