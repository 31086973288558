@import "../../scss/variables";

.Wrapper {
  display: flex;
  align-items: flex-end;

  .MoneyInput,
  .MoneyInputBordered {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    outline: none !important;
    outline-offset: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

    input {
      font: $font-size-p-b1 $font-secondary;
      width: 100%;
      line-height: 2rem;
      border: none;
      padding: 0.3rem 1rem;
    }

    .InputColor-e73180 {
      background-color: $blue-light;
    }

    .InputColor-17aee5 {
      background-color: $pink-light;
    }

    input:disabled {
      color: $black;
    }
  }

  .MoneyInputBordered input {
    border: solid $default-border-width $black;
  }
}
