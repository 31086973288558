@import "../../../scss/variables";

.MenuItem {
  .MenuItemWrapper {
    padding: 25px 0 25px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: end !important;

    .MenuItemIcon {
      margin-right: 25px;
      flex: 0 0 65px;

      .AlignRight {
        position: relative;
        left: 10px;
      }
    }

    .MenuItemContent {
      .MenuItemHeader {
        margin: 0;
      }

      .MenuItemDescription {
        font: $font-size-p-b4 $font-secondary;
        margin: 0;
      }
    }
  }
}
