$font-primary: "NexaSlabHeavy", sans-serif;
$font-primary-light: "NexaSlabHeavy-300", sans-serif;
$font-secondary: "MuseoSansRounded-300", sans-serif;
$default-font-color: #000000;

$font-size-h1: 55px;
$font-size-h2: 41px;
$font-size-h3: 35px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$font-size-h6: 13px;

$font-size-p-s1: 22px;
$font-size-p-b1: 20px;
$font-size-p-b2: 18px;
$font-size-p-b3: 16px;
$font-size-p-b4: 15px;

$line-height-h1: 83px;
$line-height-h2: 62px;
$line-height-h3: 53px;
$line-height-h4: 36px;
$line-height-h5: 30px;
$line-height-h6: 15px;

$line-height-p-s1: 33px;
$line-height-p-b1: 30px;
$line-height-p-b2: 27px;
$line-height-p-b3: 24px;
$line-height-p-b4: 23px;

/* Breakpoints */
$sm-bp: 576px;
$md-bp: 768px;
$l-bp: 992px;
$xl-bp: 1200px;
$xxl-bp: 1400px;

$font-size-sm: 0.7rem;
$font-size: 1rem;
$font-size-m: 1.125rem;
$font-size-l: 2.6rem;
$font-size-xl: 3.4rem;

$font-size-mob-sm: 0.7rem;
$font-size-mob: 1rem;
$font-size-mob-m: 1.2rem;
$font-size-mob-l: 1.8rem;
$font-size-mob-xl: 2rem;

$icon-height-l: 3rem;
$icon-height-mobile: calc($icon-height-l / 2);
$header-height: 5rem;
$header-height-mobile: calc($header-height / 2);
$blue-hader-height: 225px;

$white: #ffffff;
$grey-light: #efefef;
$grey-medium: #bcbcbc;
$grey: #9a9a9a;
$black: #000000;
$blue-light: #ddf9ff;
$blue-medium: #b5f2ff;
$blue-soft: #8bd7f2;
$blue: #17aee5;
$pink-light: #ffeff6;
$pink-medium: #f5d6e4;
$pink-soft: #f398bf;
$pink: #e73180;
$yellow-light: #fffed9;
$yellow-medium: #fffa60;
$yellow: #f0e928;
$orange-light: #ffd8c4;
$orange-medium: #ff945c;
$orange: #f16922;
$green-light: #d2f7db;
$green-medium: #69c77f;
$green: #2da249;
$red: #ff0000;

$gradient-transparent-blue: linear-gradient(rgba(255, 255, 255, 0), $blue);
$gradient-white-blue: linear-gradient(white, $blue);
$gradient-white-pink: linear-gradient(rgba(255, 255, 255, 0), $pink);
$gradient-pink-blue: linear-gradient($pink, $blue);

$default-border-width: 3px;
$default-border-icons: 2px;
$default-border-table: 1px;
$default-border-radius: 5px;
$default-padding: 1rem;
$default-box-padding: 1.5rem;

$margin-mobile: 28%;
$margin-not-mobile: 39%;

$footer-height: 3rem + 3rem + 10rem;
