@import "../../scss/variables";

.Navigation {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: $white;
  align-items: center;
  padding: 25px;

  .BrightLogo {
    margin-right: auto;
  }

  .Button {
    border: 0 none !important;
    padding-left: 25px;
    cursor: pointer;
  }
}

.PseudoRelative {
  position: relative;
  left: 100%;
  width: 0px;
  height: 0px;
}
