@import "./variables";

.EmbeddedContainer {
  padding: 0 !important;
  margin-top: -50px;
  margin-bottom: 25px;
}

.Box1 {
  width: 100%;
  padding: 25px !important;

  @media only screen and (max-width: $sm-bp) {
    padding: 12px !important;
  }
}

.Box2 {
  width: 100%;
  padding: 35px 101px 35px 101px !important;

  @media only screen and (max-width: $sm-bp) {
    padding: 12px !important;
  }
}

.Employee {
  width: 100%;
  padding: 35px 88px 35px 88px !important;

  @media only screen and (max-width: $sm-bp) {
    padding: 12px !important;
  }
}
.Mutation {
  width: 100%;
  padding: 35px 43px 35px 43px !important;
  background: $yellow-light;

  @media only screen and (max-width: $sm-bp) {
    padding: 12px !important;
  }
}

.Box3 {
  width: 100%;
  padding: 35px 25px 35px 25px !important;

  @media only screen and (max-width: $sm-bp) {
    padding: 12px !important;
  }
}

.Header-17aee5 {
  color: $blue;
  text-align: center !important;
}

.Header-e73180 {
  color: $pink;
  text-align: center !important;
}

.Header-bcbcbc {
  color: $grey-medium;
  text-align: center !important;
}

.Link-17aee5 {
  color: $blue;
  text-decoration: underline !important;
}

.Link-17aee5:hover {
  color: darken($blue, 20%);
}

.Link-e73180 {
  color: $pink;
  text-decoration: underline !important;
}

.Link-e73180:hover {
  color: darken($pink, 20%);
}

.Link-bcbcbc {
  color: darken($grey-medium, 30%);
  text-decoration: underline !important;
}

.Link-bcbcbc:hover {
  color: darken($grey-medium, 50%);
}

.Button {
  text-align: center;
  color: $white;
  font: $font-size-p-b3 $font-secondary;
  min-width: 150px;
  padding: 12px 25px 12px 25px;
  cursor: pointer;
  border-radius: 4px;
}

.DefaultHeightForInput {
  height:36px !important;
}

.LinkHrefUnderline{
  color: #17aee5 !important;
  text-decoration: underline !important;
  margin: 0 !important;
}

.Modal {
    h4 {
      font: $font-size-m $font-primary;
      text-align: center;
      padding-bottom: 2.5 * $default-padding;
    }

    .logo {
        position: absolute;
        top: -20px;
        left: -8%;
    
        @media only screen and (min-width: 768px) {
          left: -3%;
        }
      }
      .CollectionModalHeader{
        justify-content: center;
        padding: 1rem 1rem 0.5rem 1rem !important;
      }

      .HeaderTitle {
        font-size: 18px;
        font-weight: bold;
      }

      .ModalBody {
        padding: 0rem 1rem 1rem 1rem !important;

        
      }
}