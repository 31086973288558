@import "../../scss/variables";

.FooterWrapper {
  margin-top: 75px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.TopRow {
  border-style: solid solid none solid !important;
  border-color: $black !important;
  border-width: $default-border-width !important;
  border-radius: 4px 4px 0 0;
  height: 10rem;
  flex-grow: 1;
  text-align: center;
  position: relative;
}

.CallLogo {
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: -30px;
}

.SocialEnterprise {
  position: relative;
  left: 0;
  bottom: 0;

  img {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.Content {
  background: $white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.TopRow,
.BottomRow,
.EmptyRow {
  flex-shrink: 0;
}

.EmptyRow {
  height: 3rem;
}

.BottomRow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  line-height: 3rem;
  background: $black;
  color: $white;
  text-align: center;
  font: $font-size $font-secondary;

  @media screen and (max-width: 576px) {
    height: 6rem;
  }
}

.FooterText {
  line-height: $font-size-m;
  font: $font-size-p-b1 $font-secondary;
}

.FooterLink {
  color: $blue !important;
  text-decoration: underline !important;
  margin: 0 !important;
}

.FooterLink:hover {
  color: darken($blue, 20) !important;
  text-decoration: underline !important;
}

.Ready {
  font: $font-size-h5 $font-primary;
}

.BottomLink {
  color: $white;
  text-decoration: none;
}

.BottomInner {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: auto !important;
  max-width: auto !important;
  padding-right: 0 !important;
  padding-left: 40px !important;
  margin-top: 0 !important;

  li {
    line-height: 3rem;
    padding: 0 0.5rem 0 0.5rem;
  }

  @media screen and (max-width: 576px) {
    background: black;
    display: block;

    li {
      font-size: $font-size-sm;
      line-height: $font-size-sm;
      padding: 0.4rem;
    }
  }
}
