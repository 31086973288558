@import "../../scss/variables";

.MenuButton {
  cursor: pointer;
}

.Menu-false {
  display: none;
}

.Menu-true {
  padding: 25px;
  z-index: 200;
  position: absolute;
  width: 450px;
  right: 0;
  display: block;
  background: $white;
  border: solid 3px $black;
  border-radius: 4px;

  @media (max-width: $md-bp) {
    width: 100vw;
  }
}
