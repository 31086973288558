@import "../../../../scss/variables";

.DatePicker{
  width: 100% !important;
  border:none;
  height: 100%; 
  margin-top: 0.5%;
}

.InputReadonlyDiv {
  position: relative;
  width: 100%;
  height: 3.2rem;
  box-sizing: border-box;
  margin: 0.8rem 0rem;
  border:2px solid black;

}

.InputReadonlyDiv .InputReadonly {
    height: 100%;
    width: 100% !important;
    padding-right: 3.65rem;
    box-sizing: border-box;
    border: 1px solid black;
    padding-left: 1.2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

}
.InputBordered {
  border:3px solid black;
}
.DateWrapper {
  width: 49%;
  margin: 1rem .2rem 1rem 0;
}
.MoneyInput {
  width: 49%;
  margin: 1rem .2rem 1rem 0;
}
.AddFutureContribution {
  margin: 1rem .2rem;
  border: 1px solid black;
  padding: 1% 5% !important;
  background-color: #f0e928;
  margin-left: auto;
}
.DeleteFutureContribution {
  margin: 1rem .2rem;
  border: 1px solid black;
  padding: 1% 5%;
  background-color: #e73180;
  border-color: white ;
  color: white;
}

.Label {
  font-size: $font-size-h5;
  padding: .2rem 0rem;
  text-align: left;
  width: 100%;
}